.trade-pairs-panel {

  .pairs-table-scroll{
    display: flex;
    overflow-x: auto;
    margin-bottom: 30px;
    padding-bottom: 10px;

    .tradable{
      margin: 0 8px;
      color: #9A9A9E;
      border: 1px solid #373643;
      padding: 6px 18px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: bold;

      &:hover, &:focus, &.active{
        background: #E9BD42;
        color: #121213;
        cursor:pointer;
      }

      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }

    &::-webkit-scrollbar {
      height:8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #2A2C33;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #414348;
    }
  }
}
