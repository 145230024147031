.balance-history-panel {
  padding: 20px 28px 20px 44px;

  @media screen and (max-width: 768px) {
    padding: 25px 25px;
  }

  &>.header {
    .title-h2 {
      padding: 10px 0 30px;
      font-family: "Montserrat";
      font-size: 30px;
      font-weight: 700;
      color: #0F2732;
    }
  }

  &>.body {
    color: #DDDDDD;

    @media screen and (max-width: 768px) {
      overflow: auto;
    }

    display: flex;
    flex-direction: column;

    .tabs-history {
      display: flex;
      background-color: #489989;
      margin-right: auto;
      border-radius: 40px;
      overflow: hidden;

      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;

      text-transform: capitalize;

      color: #fff;

      margin-bottom: 15px;

      .item {
        padding: 20px 36px;
        cursor: pointer;
        border: 1px solid transparent;

        &:hover {}

        &.active {
          background-color: #6fb4a7;
          border-radius: 40px;
          border-color: #97c3ba;
        }
      }
    }

    .tab-history {
      max-height: 300px;
      overflow-y: auto;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #489989;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #489989;
      }

      table {
        td {
          width: 50%;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;

      tbody {
        tr {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 2px;
          border: 0.5px solid transparent;

          &:hover {
            border-color: #0C939F;
            border-radius: 10px;
            background-color: #0C939F1A;
          }
        }

        td {
          width: 33.33333%;
          padding: 10px 10px;
          white-space: nowrap;

          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #0F2732;

          .balance {
            font-size: 12px;
          }

          .circle {
            margin-right: 10px;
            width: 24px;
            height: 24px;
            background: #c4c4c4;
            border-radius: 50px;
            box-shadow: 0px 0px 1px fade-out(#fff, 0.5);
          }

          &:first-child {
            padding-left: 20px;
          }
        }
      }

      thead {
        tr {
          display: flex;
          justify-content: flex-start;

          td {
            white-space: nowrap;
            font-weight: bold;
            padding: 10px 10px;

            color: #A8ADB6;
            width: 33.33333%;

            font-family: "Montserrat";
            font-size: 10px;
            font-weight: 700;

            &:first-child {
              border-radius: 10px 0px 0px 10px;
            }

            &:last-child {
              border-radius: 0px 10px 10px 0px;
            }
          }
        }

      }
    }
  }
}

.balance-history-table {
  &__heading {
    min-width: 90px;
  }
}