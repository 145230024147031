.no-wrap {
  white-space: nowrap;
}

.trade-page {
  flex: 1;
  //background: linear-gradient(180deg, #1b4879 60%, #132c48 40%);
  // background: rgb(2, 0, 36);
  // background: linear-gradient(112deg, rgba(2, 0, 36, 1) 0%, rgba(14, 18, 21, 1) 31%, rgba(14, 18, 21, 1) 100%);
  // min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  background-image: url("/img/main-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  // background: linear-gradient(114.88deg, #F3F9F9 0%, #F9FCFC 85.59%);

  padding-left: 10px;
  padding-right: 10px;

  @media screen and (min-width: 1800px) {
    padding-left: 150px;
    padding-right: 150px;
  }

  .user-header__icons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;

    .notification {
      margin-right: 30px;
    }
  }

  .general-box {
    // overflow: hidden;

    display: flex;

    .left-wrap-col {
      width: 65%;

      // width: 858px;

      &>.body {
        display: flex;
        flex-direction: column;
      }


      @import './trade-chart-panel.scss';
      @import './wallet.scss';
      // @import "./wallet.scss";
      @import './trade-pairs.scss';
    }

    .right-wrap-col {
      // width: 300px;
      width: 35%;
      margin: 20px 0 0 20px;
      overflow: hidden;
      background-color: #ffffff;
      border-radius: 24px;
      box-shadow: 0px 4px 8px 1px #0C939F1F;

      background-image: url("/img/trade-balance-bg.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: contain;

      border: 1px solid #EEF7F6;

      .bot-terminal-panel {
        // margin-top: -50px;
        overflow: hidden;
        padding: 25px 35px;
        margin-bottom: 30px;

        &>.header {
          overflow: hidden;

          .header-box {}

          .title-h2 {
            padding: 10px 0 30px;
            font-family: "Montserrat";
            font-size: 30px;
            font-weight: 700;
            color: #0F2732;
          }
        }

        &>.body {
          border-radius: 0px 0px 10px 10px;

          .bot-terminal-panel {
            background-color: #fd0;
            border-radius: 0 0 10px 10px;
          }

          .commands {
            max-height: 200px;
            overflow-y: auto;
            padding-right: 20px;

            &::-webkit-scrollbar {
              width: 8px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
            }

            &::-webkit-scrollbar-thumb {
              background: #489989;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #489989;
            }

            .item {
              display: flex;
              padding: 10px 10px;
              color: #010F0F;
              border: 0.5px solid transparent;
              border-radius: 10px;
              align-items: center;

              font-size: 14px;
              font-weight: 400;
              line-height: 24px;

              .details {
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                  margin-bottom: 10px;
                }
              }

              &:hover {
                // background: #2A2C33;
                // border-radius: 10px;
                border-color: #0C939F;
                background-color: #0C939F1A;
              }

              .time {
                color: #A8ADB6;
                margin-right: 60px;
                padding-left: 10px;
              }

              @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: start;
                padding: 3px 0px;

                .time {
                  margin: 0px;
                  padding-left: 0px;
                }
              }
            }
          }
        }
      }

      @import './balance-history-panel.scss';

      @import './trade-pairs.scss';
    }
  }
}

@media screen and (max-width: 1200px) {
  .trade-page {
    .general-box {
      flex-wrap: wrap;
      justify-content: center;

      .left-wrap-col {
        width: 100%;
      }

      .right-wrap-col {
        width: 50%;
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 865px) {
  .trade-page .general-box .left-wrap-col .trade-block .trade-chart-panel>.header .trade-onheader {
    flex-wrap: wrap;
    margin: 0 0 45px;

    .onheader-block {
      width: 48%;
      margin: 0 0 20px;
    }
  }

  .trade-page .general-box .right-wrap-col {
    width: 100%;
    max-width: 500px;
  }

}

@media screen and (max-width: 465px) {
  .trade-page .general-box .left-wrap-col .trade-block .trade-chart-panel>.header .trade-onheader .onheader-block {
    width: 100%;
  }

  .trade-page .general-box .left-wrap-col .trade-block .trade-chart-panel>.header .trade-header {
    flex-wrap: wrap;
  }
}