
.trading-balance-panel {
  padding: 0px 35px 0px 35px;
  margin-bottom: 30px;

  .header {
    .wallet-box {
      .title {
        .title-h2 {
          color: #ffffff;
          padding: 10px 0 30px;

          font-family: 'Montserrat';
          font-size: 30px;
          font-weight: 700;
          color: #0f2732;
        }
      }
    }
  }
}
