.wallet-panel {
  height: auto;
  padding: 25px 0;
  margin-top: 20px;

  // overflow: hidden;
  .header-wrapper {
    &--desktop {
      display: flex;
      justify-content: space-between;

      .header {
        margin-left: 0;


      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .deals-header-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;

    .deals-pagination {
      margin-right: 20px;

      ul {
        margin-bottom: 0px;
      }
    }

    .deals-export {
      background: #09464B;
      border-radius: 60px;
      margin-right: 20px;
      padding: 8px 18px;
      cursor: pointer;
      text-transform: uppercase;
      color: #fff;

      &:hover,
      &.active {
        background: #09464B;
        color: #fff;
      }
    }
  }

  .header {
    padding-left: 15px;
    overflow: hidden;
    position: relative;
    width: 100px;
    margin-bottom: -45px;

    .title-h2 {
      font-family: "Montserrat";
      font-size: 30px;
      font-weight: 700;
      color: #0F2732;
    }

    .wallet-box {
      padding: 30px;

      .title {
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }
  }

  &>.body {
    padding: 0px 14px;
    // border-top: 2px solid #19385a;
    // padding-top: 10px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      padding: 0px;

      .wallet-information {
        padding: 5px !important;
      }
    }


    .table-wallet {
      border-collapse: separate;
      border-spacing: 0;
      flex: 1;

      border-spacing: 0 8px;

      td {
        &:first-child {
          padding-left: 20px;
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 20px;
            white-space: nowrap;
            color: #10131E;
            border-top: 0.5px solid transparent;
            border-bottom: 0.5px solid transparent;


            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;

            border-top: 0.5px solid #DDDDDD;
            border-bottom: 0.5px solid #DDDDDD;

            &:first-child {
              border-left: 0.5px solid #DDDDDD;
              border-radius: 10px 0 0 10px;
            }

            &:last-child {
              border-right: 0.5px solid #DDDDDD;
              border-radius: 0 10px 10px 0;
            }

            &.name {
              .name-img {
                margin-right: 5px;
              }
            }

            .circle {
              margin-right: 10px;
              width: 24px;
              height: 24px;
              background: #c4c4c4;
              border-radius: 50px;
            }

          }

          &:hover {
            td {

              // background: #282931;
              border-top: 0.5px solid #0C939F;
              border-bottom: 0.5px solid #0C939F;
              background-color: #0C939F1A;

              &:first-child {
                border-left: 0.5px solid #0C939F;
                border-radius: 10px 0 0 10px;
              }

              &:last-child {
                border-right: 0.5px solid #0C939F;
                border-radius: 0 10px 10px 0;
              }
            }
          }
        }

        tr:not(.buy-row) td {
          // background-color: fade-out(#8f413b, 0.7);
          // background-color: fade-out(#282931, 0.3);
        }
      }

      thead {
        td {
          white-space: nowrap;
          padding: 10px 20px;
          text-transform: uppercase;
          color: #9A9A9E;

          font-family: "Montserrat";
          font-size: 10px;
          font-weight: 700;
          line-height: 14px;

          &:first-child {
            border-radius: 10px 0px 0px 10px;
          }

          &:last-child {
            border-radius: 0px 10px 10px 0px;
          }
        }
      }
    }

    .wallet-information {
      overflow: hidden;
      overflow-x: auto;
      display: flex;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #489989;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #489989;
      }
    }

    @media screen and (max-width: 1300px) {
      .wallet-information {
        padding: 15px;
      }
    }
  }

  .deals-pagination {
    &--top {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--bottom {
      @media screen and (min-width: 769px) {
        display: none;
      }

      display: flex;
      margin-top: 20px;
      justify-content: center !important;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        position: relative;

        &.active {
          button {
            color: #0C939F;
          }
        }

        &:first-child,
        &:last-child {

          span,
          button {
            border: 1px solid #75D4DB;
            background-color: #0B909B33;
            font-size: 0;
            color: transparent;
            padding: 18px;
          }
        }

        &:first-child,
        &:last-child {
          &:before {
            width: 6.1px;
            height: 11.2px;
            position: absolute;
          }
        }

        &:first-child {
          &:before {
            content: '';
            background: url("/img/pagination-right.svg") no-repeat;
            transform: rotate(180deg);
            width: 6.9px;
          }

          &:hover {
            &:before {
              // background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDYgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjczODU0IDYuMTg5MDdDNS44NTU3OSA2LjA3MTk3IDUuOTIxNzMgNS45MTMxMSA1LjkyMTg4IDUuNzQ3NFY1LjQzMDc0QzUuOTE5OTUgNS4yNjUzOSA1Ljg1NDI4IDUuMTA3MTcgNS43Mzg1NCA0Ljk4OTA3TDEuNDU1MjEgMC43MTQwN0MxLjM3Njk3IDAuNjM1MTg5IDEuMjcwNDcgMC41OTA4MiAxLjE1OTM4IDAuNTkwODJDMS4wNDgyOCAwLjU5MDgyIDAuOTQxNzc4IDAuNjM1MTg5IDAuODYzNTQyIDAuNzE0MDdMMC4yNzE4NzUgMS4zMDU3NEMwLjE5MzQ4OCAxLjM4MjU0IDAuMTQ5MzE1IDEuNDg3NjYgMC4xNDkzMTUgMS41OTc0QzAuMTQ5MzE1IDEuNzA3MTQgMC4xOTM0ODggMS44MTIyNyAwLjI3MTg3NSAxLjg4OTA3TDMuOTgwMjEgNS41ODkwN0wwLjI3MTg3NSA5LjI4OTA3QzAuMTkyOTk1IDkuMzY3MzEgMC4xNDg2MjYgOS40NzM4IDAuMTQ4NjI2IDkuNTg0OUMwLjE0ODYyNiA5LjY5NiAwLjE5Mjk5NSA5LjgwMjUgMC4yNzE4NzUgOS44ODA3NEwwLjg2MzU0MiAxMC40NjQxQzAuOTQxNzc4IDEwLjU0MjkgMS4wNDgyOCAxMC41ODczIDEuMTU5MzggMTAuNTg3M0MxLjI3MDQ3IDEwLjU4NzMgMS4zNzY5NyAxMC41NDI5IDEuNDU1MjEgMTAuNDY0MUw1LjczODU0IDYuMTg5MDdaIiBmaWxsPSIjRTlCRDQyIi8+Cjwvc3ZnPgo=) no-repeat;
              // transform: rotate(180deg);
              opacity: 0.7;
            }
          }
        }

        &:last-child {
          &:before {
            content: '';
            background: url("/img/pagination-right.svg") no-repeat;
          }

          &:hover {
            &:before {
              // background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDYgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjczODU0IDYuMTg5MDdDNS44NTU3OSA2LjA3MTk3IDUuOTIxNzMgNS45MTMxMSA1LjkyMTg4IDUuNzQ3NFY1LjQzMDc0QzUuOTE5OTUgNS4yNjUzOSA1Ljg1NDI4IDUuMTA3MTcgNS43Mzg1NCA0Ljk4OTA3TDEuNDU1MjEgMC43MTQwN0MxLjM3Njk3IDAuNjM1MTg5IDEuMjcwNDcgMC41OTA4MiAxLjE1OTM4IDAuNTkwODJDMS4wNDgyOCAwLjU5MDgyIDAuOTQxNzc4IDAuNjM1MTg5IDAuODYzNTQyIDAuNzE0MDdMMC4yNzE4NzUgMS4zMDU3NEMwLjE5MzQ4OCAxLjM4MjU0IDAuMTQ5MzE1IDEuNDg3NjYgMC4xNDkzMTUgMS41OTc0QzAuMTQ5MzE1IDEuNzA3MTQgMC4xOTM0ODggMS44MTIyNyAwLjI3MTg3NSAxLjg4OTA3TDMuOTgwMjEgNS41ODkwN0wwLjI3MTg3NSA5LjI4OTA3QzAuMTkyOTk1IDkuMzY3MzEgMC4xNDg2MjYgOS40NzM4IDAuMTQ4NjI2IDkuNTg0OUMwLjE0ODYyNiA5LjY5NiAwLjE5Mjk5NSA5LjgwMjUgMC4yNzE4NzUgOS44ODA3NEwwLjg2MzU0MiAxMC40NjQxQzAuOTQxNzc4IDEwLjU0MjkgMS4wNDgyOCAxMC41ODczIDEuMTU5MzggMTAuNTg3M0MxLjI3MDQ3IDEwLjU4NzMgMS4zNzY5NyAxMC41NDI5IDEuNDU1MjEgMTAuNDY0MUw1LjczODU0IDYuMTg5MDdaIiBmaWxsPSIjRTlCRDQyIi8+Cjwvc3ZnPgo=) no-repeat;
              // transform: rotate(0deg);
              opacity: 0.7;
            }
          }
        }

        &.disabled {
          pointer-events: none;
          &:before {
            opacity: 0.3;
          }
        }

        span,
        button {
          width: 10px;
          height: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          background: transparent;
          border-radius: 50%;
          padding: 15px;
          color: #A8ADB6;
          font-size: 14px;
          font-weight: bold;

          &:focus {
            box-shadow: none;
            color: #0C939F;
          }

          &:hover {
            color: #0C939F;
          }

          [aria-checked="true"] {
            color: #0C939F;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .wallet-panel {
    width: auto;
  }
}

@media screen and (max-width: 470px) {
  .wallet-panel {
    &>.header {
      margin-bottom: 0px;
    }
  }
}