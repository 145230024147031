.trade-block {
  .markets-block {
    .title {
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        text-transform: capitalize;
        color: #343d5a;
      }
    }

    .markets-information-list {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      padding: 20px 0;
      border-top: 1px solid #e6ecf5;
      border-bottom: 1px solid #e6ecf5;

      .item {
        width: 100%;

        .low-title {
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            color: #989dbe;
          }
        }

        .value-block {
          display: flex;
          align-items: center;
          margin-top: 23px;

          .value {
            span {
              font-style: normal;
              font-weight: bold;
              font-size: 36px;
              line-height: 36px;
              color: #343d5a;
            }
          }

          .bad {
            padding: 5px 10px;
            border-radius: 20px;
            background: rgba(237, 0, 33, 0.2);
            margin-left: 25px;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              color: #d10b0b;
            }
          }

          .good {
            padding: 5px 10px;
            border-radius: 20px;
            background: rgba(131, 186, 59, 0.2);
            margin-left: 25px;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              color: #83ba3b;
            }
          }
        }
      }

      .high {
        padding-left: 40px;
        border-left: 1px solid #e6ecf5;
      }

      .low {
        padding-left: 40px;
        border-left: 1px solid #e6ecf5;
      }

      .volume {
        padding-left: 40px;
        border-left: 1px solid #e6ecf5;
      }
    }
  }

  .trade-chart-panel {
    // background-color: #fefefe;
    border-radius: 20px;

    &>.header {
      padding: 20px 10px;
      padding-bottom: 0px;
      // background-color: #f8f9fa;
      border-radius: 10px 10px 0px 0px;

      .trade-onheader {
        display: flex;
        justify-content: space-between;
        margin: 0 -20px 45px;

        .onheader-block {
          background-image: url('/img/onheader-bg-right.png'), url('/img/onheader-bg-left.png');
          background-position: right bottom, left bottom;
          background-size: contain;
          background-repeat: no-repeat;

          display: flex;
          flex-wrap: wrap;
          width: 23%;
          color: #10131E;
          font-size: 26px;
          padding: 17px 27px;
          justify-content: space-between;
          margin: 0 20px;

          background-color: #fff;
          border-radius: 24px;
          box-shadow: 0px 4px 8px 0px #0C939F1F;

          .onheader-title {
            color: #A8ADB6;
            font-size: 16px;
            font-weight: 400;
            padding: 5px 0;
          }

          .onheader-persents {
            padding: 3px 10px;
            border-radius: 20px;
            align-self: baseline;
            color: #0E0E0E;

            font-family: "Josefin Sans";
            font-size: 14px;
            font-weight: 600;

            &.red {
              // background: #8F413B;
              background: #FF355166;
            }

            &.green {
              color: #121212;
              // background: #A1D390;
              background: #0ECB8166;
            }
          }

          .onheader-main {
            width: 100%;
            display: flex;
            align-items: flex-end;

            font-size: 26px;
            font-weight: 700;
            line-height: 31px;
          }
        }
      }

      .trade-header {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 20px;

        background-color: #fff;
        border-radius: 24px 24px 0 0;
        padding-bottom: 25px;
        padding-left: 30px;
        padding-top: 33px;
        padding-right: 44px;
        // box-shadow: 0px 4px 20px 0px #0C939F1F;

        box-shadow: 0px 5px 5px 1px #0C939F1F;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .status {
          display: flex;
          align-items: center;

          .total-balance {
            width: 100%;
            color: #9A9A9E;
            text-align: right;
            margin-bottom: 15px;

            font-family: "Montserrat";
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;

            @media screen and (max-width: 768px) {
              text-align: left;
            }
          }
        }

        .options__box {
          width: 100%;
          display: flex;
          color: #011240;
          flex-wrap: wrap;

          .options {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 768px) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              overflow: auto;
            }

            .no-wrap {
              @media screen and (max-width: 768px) {
                margin-bottom: 10px;
              }
            }

            .item {
              display: flex;
              padding: 6px 0;

              font-family: "Montserrat";
              font-size: 12px;
              font-weight: 600;

              span {
                cursor: pointer;
                font-weight: bold;
                color: #fff;
                font-size: 12px;
                text-transform: uppercase;
              }

              @media screen and (max-width: 1300px) {
                span {
                  font-size: 9px;
                }
              }

              &.item:first-child {
                background: #2A2C33;
                border-radius: 60px;
                margin-right: 20px;
                padding: 8px 18px;
                cursor: pointer;
                text-transform: uppercase;
                color: #fff;

                &:hover,
                &.active {
                  background: #09464B;
                  color: #fff;
                }
              }
            }

            .item-time {
              display: flex;
              align-items: center;
              margin-right: 0px;
              background-color: #E1EEEC;
              padding: 8px 18px;
              border-radius: 20px;

              select {
                padding-right: 20px;
                padding-top: 0px;
                padding-bottom: 1px;
                border: unset;
                background: unset;
                cursor: pointer;
                text-transform: uppercase;
              }

              span,
              select {
                font-size: 12px;
                color: #0B8D98;
                font-weight: 600;
              }
            }
          }


          .item .toggle-view {
            .original {
              margin-right: 15px;
            }

            span {
              padding-left: 5px;

              &.active {
                color: #0169f7;
              }
            }
          }
        }

        .chain {
          display: flex;
          align-items: center;

          .chain__info-box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-weight: 700;

            .pair-name {
              display: flex;
              align-items: center;
              padding-right: 12px;
              width: 100%;
              color: #9A9A9E;
              font-size: 24px;
              // border-right: 2px solid #0c39b0;

              img {
                margin-right: 10px;
              }

              .crypto-currency {
                margin-left: 8px;

                span {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 24px;
                  line-height: 24px;
                  text-transform: uppercase;
                  color: #989dbe;
                }
              }
            }

            .pair-price {
              color: #10131E;
              font-family: "Montserrat";
              font-size: 36px;
              font-weight: 700;
              letter-spacing: 0em;

              span {
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 36px;
                color: #343d5a;
              }
            }
          }
        }
      }
    }

    .header__bottom-title {
      padding: 20px 30px;
      border-radius: 30px;
      background: #0c39b0;

      .title {
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }

    &>.body {
      padding: 10px 30px;
      padding-bottom: 25px;
      background-color: #fff;
      border-radius: 0 0 24px 24px;
      margin: 0px 10px;
      box-shadow: 0px 5px 5px 1px #0C939F1F;
      // box-shadow: 0px 4px 20px 0px #0C939F1F;
      position: relative;
      z-index: 1;

      .trading-vue-legend {
        top: -32px !important;
        color: #4C9D8D;

        font-family: "Montserrat";
        font-size: 10px;
        font-weight: 600;

        .t-vue-lspan {
          color: #A8ADB6;
          font-weight: 500;
          margin-right: 10px;
        }
      }

      .market-box {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 8px;
        background: linear-gradient(149.98deg, #0e5d4a 10.49%, #069975 81.69%);
        border-radius: 10px;
        margin: 10px 0;

        .market-logo {
          img {
            display: flex;
          }
        }

        .title {
          margin-left: 15px;

          span {
            font-weight: 500;
            font-size: 16px;
            color: #2a2a2a;
          }
        }
      }
    }
  }
}